<template>
	<div id="mainIndex">
		<el-row :gutter="20">
			<!-- 常用功能 -->
			<el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
				<div style="background-color: #FFFFFF;">
					<div class="index-title" style="padding-bottom: 0;">常用功能</div>
					<div class="features">
						<div class="u-f-justify u-f-column features-item" v-for="(item, index) in features" :key="index" @click="toUrl(item.url)">
							<img :src="img_url + item.icon" style="width: 25%;margin-bottom: 5px;" />
							<div>{{ item.title }}</div>
						</div>
						<div class="u-f-justify u-f-column features-item" @click="activityShow">
							<img src="../assets/image/add.png" style="width: 25%;margin-bottom: 5px;" />
							<div>添加</div>
						</div>
					</div>
				</div>
				<!-- 代办提示 -->
				<div class="Todo" v-if="statisticsData">
					<div class="title u-f-item">
						<div>课时量统计</div>
						<!-- <span>0</span> -->
					</div>
					<div class="u-f-item" style="padding-bottom: 1.25rem;">
						<div class="u-f1 u-f-justify u-f-column" @click="toUrl('my',2,1)" style="cursor: pointer;">
							<div class="num">{{statisticsData.standard||0}}</div>
							<div>总课时量</div>
						</div>
						<div class="u-f1 u-f-justify u-f-column" @click="daike" style="cursor: pointer;">
							<div class="num">{{statisticsData.replace||0}}</div>
							<div>代课课时量</div>
						</div>
						<div class="u-f1 u-f-justify u-f-column" @click="daike" style="cursor: pointer;">
							<div class="num">{{statisticsData.replaces||0}}</div>
							<div>被代课课时量</div>
						</div>
						<!-- <div class="u-f1 u-f-justify u-f-column">
							<div class="num">{{statisticsData.exceed||0}}</div>
							<div>超课时量</div>
						</div> -->
						<div class="u-f1 u-f-justify u-f-column" style="cursor: pointer;">
							<div class="num">{{statisticsData.sum||0}}</div>
							<div>合计课时</div>
						</div>
					</div>
					
				</div>
				<!-- 消息、日程、公告 -->
				<div class="schedule" style="margin-top: 1.04vw;margin-bottom: 1.04vw;;">
					<div class="title" style="height: 5vh;line-height: 5vh;">
						<div style="font-size: 0.833vw;color: #454545;font-weight: bold;">
							学校公告
							<span class="schedule_num">{{list.length}}</span>
						</div>
					</div>
					<div style="height:31vh;" class="notice">
						<div class="u-f-item m_b" v-for="(item,index) in list" :key="index" v-if="list.length">
							<i class="iconfont icon-yuandian1"></i>
							<div class="u-line-1">{{item.title}}</div>
						</div>
						<el-empty description="暂无公告内容" :imageSize="50" v-if="!list.length"></el-empty>
					</div>
				</div>
			</el-col>
			<!-- 课程表 -->
			<el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12" style="height: 87.5vh;">
        <v-weburl-iframe :url="`teacher/table?teacher_id=${userId}`" />
			</el-col>
		</el-row>
		<el-dialog title="常用功能" :visible.sync="activityVisible" width="1000px" :before-close="handleClose">
			<div class="u-f">
				<div class="meun-left">
					<div class="u-f-justify meun-left-item" @click="tabtap(index)" :class="meunIndex == index ? 'active' : ''" v-for="(item, index) in meunList" :key="index">
						{{ item.title }}
					</div>
				</div>
				<div class="meun-right" v-if="meunList.length">
					<div class="meun-title u-f-item font_blod" v-if="meunList.length">
						<span class="line"></span>
						{{ meunList[meunIndex].title }}
					</div>
					<div class="u-f meun-right-item" v-if="meunList.length" v-for="(items, index) in meunList[meunIndex].children" :key="index">
						<div>
							<div class="drawer-meun-title">{{ items.title }}</div>
						</div>
						<div class="drawer-meun-list">
							<div class="drawer-meun-item u-f-justify u-f-column" :class="item.fast?'select':''" v-for="(item, i) in items.children" :key="i" @click="topage(item)">
								<img :src="img_url + item.icon" alt="" />
								<div class="font_14">{{ item.title }}</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<span slot="footer" class="dialog-footer u-f-item u-f-jsb">
				<el-button @click="activityVisible = false" size="small">取 消</el-button>
				<el-button type="primary" size="small" @click="submit()">确 定</el-button>
			</span>
		</el-dialog>
		
	</div>
</template>

<script>
import base from '@/api/base.js';
import KTeacherTable from "@/components/teacher-table";
import VWeburlIframe from "@/components/weburl-iframe";
export default {
	name: 'mainIndex',
  components: {VWeburlIframe, KTeacherTable},
  data() {
		return {
			value1: '',
			activityVisible: false,
			meunList: [],
			meunIndex: 0,
			img_url: base.imgUrl,
			week: '',
			weekList: [],
			CourseList:'',
			curriculum_time: [],
			attend_day: [],
			courseData: [],
			ids:[],
			features: [],
			list:[],
			dmoe:false,
			statisticsData:'',
      userId: 0,
		};
	},
	created() {},
	mounted() {
    this.userId = Number(localStorage.getItem('id') ?? 0)
		this.getUserRule()
		this.getstatistics()
	},
	methods: {
		getstatistics(){
			this.$api.station.statistics({}).then(res=>{
				if(res.data.code==1){
					this.statisticsData = res.data.data;
				}
			})
		},
		systemMsgList(){
			this.$api.rule.systemMsgList({
				type:1,
				event:20,
				page:this.page
			}).then(res=>{
				if(res.data.code==1){
					this.list = res.data.data.rows
				}
			})
		},
		daike(){
			this.$router.push('/pronoun');
		},
		toUrl(url,index,i){
			if (url) {
				if (url == 'my') {
					if(index&&i){
						this.$router.push({
							path: '/archives_details',
							query: {
								id: window.localStorage.getItem('id'),
								index:index,
								i:i
							}
						});
					}else{
						this.$router.push({
							path: '/archives_details',
							query: {
								id: window.localStorage.getItem('id')
							}
						});
					}
				} else {
					this.$router.push(url);
				}
			} else {
				this.$message.error('正在努力开发中，敬请期待！');
			}
		},
		getUserRule(){
			this.$api.setting.getUserRule({}).then(res=>{
				if(res.data.code==1){
					this.features = res.data.data;
				}
			})
		},
		activityShow(){
			this.userRules();
			this.activityVisible = true;
		},
		topage(item){
			if(!item.fast&&this.ids.length==9){
				return this.$message.error("最多只能生成9个")
			}else{
				item.fast = !item.fast;
				if(this.ids.indexOf(item.id) > -1){
					this.ids.splice(this.ids.indexOf(item.id),1)
				}else{
					this.ids = this.ids.concat(item.id);
				}
			}
			console.log(this.ids)
		},
		submit(){
			this.$api.setting.setuserRule({
				id:this.ids.toString()
			}).then(res=>{
				if(res.data.code==1){
					this.$message.success('设置成功')
					this.getUserRule()
					this.activityVisible = false;
				}else{
					this.$message.error(res.data.msg)
				}
			})
		},
		// 获取我的权限列表
		userRules() {
			this.$api.login.userRules().then(res => {
				if (res.data.code == 1) {
					let data = res.data.data;
					let arr = [];
					for(let i in data){
						for(let j in data[i].children){
							for(let y in data[i].children[j].children){
								if(data[i].children[j].children[y].fast){
									arr.push(data[i].children[j].children[y].id)
								}
							}
						}
					}
					this.ids = arr;
					this.meunList = data;
				}
			});
		},
		handleClose(done) {
			done();
		},
		tabtap(index) {
			this.meunIndex = index;
		},
	}
};
</script>

<style lang="scss">
#mainIndex {
	.features {
		display: flex;
		flex-wrap: wrap;
		padding: 0.52vw;
		.features-item {
			width: 20%;
			padding: 1vw 0;
			color: #4a4a4a;
			.iconfont {
				background: #fe5658;
				color: #ffffff;
				font-size: 1.3vw;
				width: 2.6vw;
				height: 2.6vw;
				border-radius: 1.125rem;
				margin-bottom: 0.625rem;
			}
			.icon-banji {
				background-color: #fb8d55;
			}
			.icon-13 {
				background-color: #5dcf6e;
			}
			.icon-wenjian {
				background-color: #ff5087;
			}
			.icon-dangan {
				background-color: #fb8d55;
			}
			.icon-baoxiu {
				background-color: #5dcf6e;
			}
			.icon-jiaowuguanli {
				background-color: #fe5658;
			}
			.icon-shenpi {
				background-color: #55b0fe;
			}
			.icon-diannao {
				background-color: #fb8d55;
			}
			.icon-jia {
				background-color: #e6e6e6;
				color: #5c5c5c;
			}
		}
		.features-item:hover {
			background-color: #eeeeee !important;
		}
	}
	.remind {
		background-color: #ffffff;
		padding: 1.04vw 1.5625vw;
		position: relative;
		color: #6e6e6e;
		.remind-img {
			position: absolute;
			right: 0;
			bottom: 0;
			width: 20%;
		}
		.stdusNum {
			font-size: 1.979vw;
			color: #3ca1fd;
			margin-bottom: 0.3125rem;
		}
		.sNum {
			color: #f5b75b;
			font-size: 1.979vw;
			margin-bottom: 0.3125rem;
		}
		.thatNum {
			color: #5ed76e;
			font-size: 1.979vw;
			margin-bottom: 0.3125rem;
		}
	}
	.schedule {
		background-color: #ffffff;
		.title {
			height: 60px;
			padding: 0 15px;
			line-height: 60px;
			border-bottom: 1px solid #f3f3f3;
			.el-input--suffix .el-input__inner {
				padding-right: 0;
			}
		}
		.schedule_num {
			font-weight: 100;
			font-size: 0.729vw;
			background-color: #ffdede;
			color: #ff7171;
			padding: 0.1vw 0.416vw;
			border-radius: 1.25rem;
		}
		.schedule-content {
			padding: 25px 15px 0 15px;
			.schedule-item {
				background-color: #f9f9f9;
				padding: 0 0.9375rem 1.5625rem 0.78125vw;
				.schedule-title {
					background-color: #eeeeee;
					text-align: center;
					width: 5.2vw;
					font-size: 0.729vw;
					padding: 0.15625vw 0 0.52vw 0;
					color: #4d565a;
					position: relative;
				}
				.schedule-title::before {
					position: absolute;
					height: 0.52vw;
					left: 0;
					right: 0;
					content: '';
					background-color: #f9f9f9;
					border-radius: 80%;
					bottom: -0.26vw;
				}
			}
		}
	}
	.msg {
		background-color: #ffffff;
		padding: 20px 15px;
		.scroll-content {
			height: 1.04vw;
			overflow: hidden;
		}
	}
	.notice {
		padding: 0.78125vw;
		overflow-x: hidden;
		overflow-y: auto;
		-ms-overflow-style: none;
		overflow: '-moz-scrollbars-none';
		scrollbar-width: none;
		div {
			font-size: 0.729vw;
			color: #636363;
			i {
				font-size: 1.04vw;
				color: #55b0fe;
			}
		}
	}
	.notice::-webkit-scrollbar {
		display: none;
	}
	.table {
		padding: 0;
		.th-1 {
			width: 8%;
			height: 70px;
			border-right: 1px solid #EEEEEE;
			border-bottom: 1px solid #EEEEEE;
		}
		.th {
			width: 13.5%;
			text-align: center;
			height: 70px;
			border-right: 1px solid #EEEEEE;
			border-bottom: 1px solid #EEEEEE;
		}
		.active{
			border: 1px solid #FF6B5A;
			background-color: #EEFAF6;
		}
	}
	.index-title {
		padding: 0.95rem 0.5rem 0.95rem 1.875rem;
		font-size: 1.125rem;
		color: #454545;
		font-weight: bold;
	}
	.el-dialog__header {
		border-bottom: 1px solid #eeeeee;
		padding: 10px 20px;
		.el-dialog__title {
			font-size: 16px;
		}
	}
	.el-dialog__body {
		padding: 0;
		height: 600px;
		.meun-left {
			width: 150px;
			border-right: 1px solid #eeeeee;
			height: 590px;
			padding-top: 10px;
			.meun-left-item {
				padding: 10px 0;
				cursor: pointer;
			}
			.active {
				background-color: #e4f1ff;
				color: #2d8cf0;
				border-right: 2px solid #2d8cf0;
			}
		}
		.meun-right {
			padding: 20px 20px 20px 30px;
			overflow-y: auto;
			height: 560px;
			width: 80%;
			.meun-title {
				font-size: 0.8333vw;
				.line {
					width: 3px;
					border-radius: 20px;
					height: 15px;
					background-color: #2d8cf0;
					margin-right: 5px;
				}
			}
			.meun-right-item {
				padding: 20px 0;
				.drawer-meun-title {
					width: 20px;
					line-height: 15px;
					padding: 5px 5px 8px 10px;
					background-color: #f6f6f6;
					border-top-left-radius: 20px;
					border-bottom-left-radius: 20px;
					border-bottom-right-radius: 20px;
				}
				.drawer-meun-list {
					padding-left: 30px;
					display: flex;
					flex-wrap: wrap;
					.drawer-meun-item {
						cursor: pointer;
						width: 100px;
						height: 100px;
						margin-right: 15px;
						margin-bottom: 15px;
						position: relative;
						img {
							margin-bottom: 5px;
							width: 40px;
						}
						div {
							color: #696969;
						}
					}
					.drawer-meun-item:hover {
						background-color: #eeeeee !important;
					}
					.select {
					    background-color: #ebf3ff;
					    color: #5999fc;
					}
					 
					.select:before {
					     content: '';
						position: absolute;
						right: 0;
						bottom: 0;
						border: 12px solid #5999fc;
						border-top-color: transparent;
						border-left-color: transparent;
					}
					.select:after {
						content: '';
						width: 3px;
						height: 8px;
						position: absolute;
						right: 4px;
						bottom: 4px;
						border: 2px solid #fff;
						border-top-color: transparent;
						border-left-color: transparent;
						transform: rotate(45deg);
					}
				}
			}
		}
	}
	.el-dialog__footer {
		padding: 15px 20px 15px;
		box-shadow: 0px -5px 10px -5px #eeeeee;
	}
	.Todo {
		background-color: #ffffff;
		margin-top: 20px;
		.title {
			padding: 1.25rem 1.875rem;
			& > div {
				font-size: 1.125rem;
				font-weight: bold;
				color: #454545;
			}
			span {
				background-color: #ffdede;
				border-radius: 20px;
				padding: 2px 10px;
				color: #ff6464;
				margin-left: 10px;
			}
		}
		.num {
			font-size: 1.425rem;
			color: #4698ff;
			margin-bottom: 10px;
		}
	}
}
</style>
