<!-- 老师课表 -->
<template>
  <div style="background-color: #FFFFFF;height: 100%;" class="table" ref="print">
    <div class="u-f-item u-f-jsb no-print">
      <div class="u-f-item">
        <div class="index-title">课程表</div>
        <el-select v-model="week" placeholder="请选择" style="width: 120px;" size="medium" @change="weekChange">
          <el-option v-for="item in weekList" :key="item.id" :label="item.name" :value="item.id"></el-option>
        </el-select>
      </div>
      <div class="u-f-item" style="padding-right: 20px;">
        <el-button size="mini" @click="onPrint">打印</el-button>
        <!-- <el-button size="mini" type="primary">导出</el-button> -->
      </div>
    </div>
    <div class="u-f table" style="border-top: 1px solid #EEEEEE;" v-if="attend_day.length">
      <div class="th-1 u-f-justify u-f-column" style="height: 50px;"></div>
      <div class="th u-f-justify" style="height: 50px;" v-for="(item, index) in attend_day" :key="index">
        <div class="font_14">{{item.name}}</div>
        <div class="font_12" style="color: #A4A4A4;">（{{item.date}})</div>
      </div>
    </div>
    <div class="u-f table" v-for="(item,index) in curriculum_time" :key="index">
      <div class="th-1 u-f-justify u-f-column">
        <div class="font_12">{{item.node}}</div>
        <div class="font_12" style="color: #A4A4A4;">{{item.start}}</div>
        <i style="line-height: 3px;color: #A4A4A4;">-</i>
        <div class="font_12" style="color: #A4A4A4;">{{item.end}} </div>
      </div>
      <div class="th u-f-justify u-f-column" :class="val.class?'active':''" v-for="(val,i) in item.list" :key="i">
        <el-tooltip placement="bottom">
          <div slot="content">
            <div class="font_12" style="border-bottom: 1px dashed #fff;padding-bottom:10px;margin-bottom:10px">班级：{{val.class_name||''}}</div>
            <div class="font_12">学科：{{val.school_discipline_name||''}}</div>
          </div>
          <div>
            <div class="font_12">{{val.class_name||''}}</div>
            <div class="font_12">{{val.school_discipline_name||''}}</div>
          </div>
        </el-tooltip>
      </div>
    </div>
  </div>
</template>

<script>
import v2Semester from "@/api/v2/semester";

export default {
  name: 'KTeacherTable',
  data() {
    return {
      week: 0,
      weekList: [],
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    async init() {
      const res = await v2Semester.getAllList()
      console.log('init', res)
    },
    onPrint() {
      this.$print(this.$refs.print)
    }
  }
}
</script>

<style scoped>

</style>